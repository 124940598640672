.account_page {
  .material-shadows {
    & > .card-body {
      min-height: 290px;
      &.empty {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.order_data {
  .header {
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 20px;
      font-weight: 400;
      color: #333;
    }
  }
  .order_info {
    h5 {
      font-size: 15px;
    }
    p {
      margin: 0;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }
  }
}
