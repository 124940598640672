.designmathasa {
  a {
    color: $design-color-success;
  }
  .footer {
    background-color: $design-color-background;
    .footer-info {
      h3,
      h2,
      p {
        color: $text-default;
      }
      a,
      button {
        color: $text-default;
        &.active {
          color: $design-color-success;
        }
      }
    }
  }
  .navbar_items {
    a,
    button {
      &.navbar-brand {
        &.alto_valor {
          color: $text-default;
          font-size: 17px;
          border-radius: 0;
          background: none;
          padding: 0 5px;
          box-shadow: none;
        }
      }
    }
  }
  .top_nav {
    background-color: $design-text-blue;
    a,
    span,
    button {
      color: $text-default;
    }
  }
  .btn-cart {
    background-color: $design-cta-cart;
    color: white !important;
    &:hover {
      background-color: darken($color: $design-cta-cart, $amount: 5) !important;
    }
  }
  .btn-outline-cart {
    border: 1px solid $design-cta-cart;
    color: $design-cta-cart;
    &:hover {
      background-color: $design-cta-cart;
      color: white !important;
    }
  }
  .page-link {
    color: $design-color-success;
    &:hover {
      color: darken($color: $design-color-success, $amount: 5);
    }
  }
  .page-item.active .page-link {
    background-color: $design-color-success;
    border-color: $design-color-success;
  }
  .underlined {
    border-bottom: 3px solid $design-color-success;
  }
  .badge_main,
  .badge_main.bold {
    background-color: $design-text-blue;
    color: $design-color-success;
  }
  .btn-outline-danger {
    border: 1px solid $design-color-danger;
    color: $design-color-danger;
    &:hover {
      background-color: $design-color-danger;
      color: white;
    }
  }
}
