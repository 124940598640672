.empty_jumbo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  color: $text-light;
  p {
    margin: 0;
    margin-top: 10px;
    font-size: 14px;
  }
  h3 {
    font-size: 1.5em;
    margin: 0;
    margin-top: 10px;
    font-weight: normal;
  }
  i {
    display: block;
    font-size: 2em;
  }
}

.alert {
  &-slide-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  &-shake {
    -webkit-animation: shake-horizontal 0.8s
      cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      both;
  }
  &-fixed {
    position: fixed;
    z-index: 1070;
    top: -500px;
    transition: top ease-in-out 0.5s;
    min-width: 500px;
    &.alert-right {
      right: 30px;
    }
    &.active {
      top: 40px;
    }
  }

  &-danger {
    background-color: #ff595e;
    border-color: #ff595e;
    color: white;
  }
  &-warning {
    background-color: #ffca3a;
    border-color: #ffca3a;
    color: white;
  }
}
