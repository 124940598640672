.page-loading {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  &.hero_loader {
    min-height: 362px;
    display: flex;
    align-items: center;
    background-color: #cbcbcb;
    .css-0 {
      div {
        background-color: white;
        opacity: 0.8;
      }
    }
  }

  p {
    color: $text-light;
    margin-top: 20px;
  }
}
