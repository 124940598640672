.icon_rounded {
  background-color: $text-lightest;
  color: $text-default;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  i {
    font-size: 20px;
  }
  &.white {
    background-color: white;
    color: $text-light;
    border: 3px solid $secondary-color;
  }
}
