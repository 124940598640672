.no_padding{
	padding: 0!important;
}
.no_padding_top{
	padding-top : 0!important;
}
.no_padding_bottom{
	padding-bottom : 0!important;
}
.no_padding_left{
	padding-left:  0!important;
}
.no_padding_rigth{
	padding-right: 0!important;
}
.no_margin{
	margin:0!important;
}

.no_user_select {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.default_margin_top{
	margin-top: 15px;
}

.default_margin_bottom{
	margin-bottom: 15px;
}

