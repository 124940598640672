.product {
  margin-top: 20px;
  .images {
    text-align: center;
    img {
      max-height: 500px;
    }
    .BrainhubCarousel__dots {
      img {
        max-height: 100px;
      }
    }
  }
  .right {
    padding: 0 5%;
    .desc {
      border-bottom: 1px solid $text-lighter;
      margin-bottom: 20px;
      padding-bottom: 20px;
      h1 {
        font-size: 1.5em;
        color: $text-default;
      }
      p {
        &:not(.long-desc) {
          font-size: smaller;
          color: $text-light;
          margin: 0;
        }
        &.long-desc {
          margin-top: 15px;
          margin-bottom: 0;
          color: $text-default;
          font-size: 18px;
        }
      }
    }
    .pricing {
      display: flex;
      align-items: flex-end;
      h2 {
        align-items: center;
        font-weight: 400;
        span {
          text-align: left;
          margin-bottom: 5px;
          width: 100%;
          font-weight: 100;
          font-size: 60%;
          color: $main-color-dark;
          &.line {
            text-decoration: line-through;
          }
        }
      }
      .unit {
        margin-left: 10px;
        padding-bottom: 5px;
        opacity: 0.7;
      }
    }
  }
  .images {
    .BrainhubCarousel__dots {
      justify-content: flex-start;
      li {
        width: 100px;
        button {
          background: transparent;
        }
      }
    }
  }
}
.features-section {
  .nav {
    margin-bottom: 20px;
    li {
      button.nav-link {
        border: none;
        border-bottom: 1px solid #fff;
        padding-bottom: 0;
        border-radius: 0;
        background-color: #fff;
        &.active {
          border-bottom: 2px solid $main-color;
          color: $text-default;
        }
      }
    }
  }
  .tab-content {
    border-radius: 10px;
    border: 1px solid $text-lighter;
    padding: 20px;
    font-size: 15px;
    p {
      margin: 0;
    }
  }
}

.product_block_list,
.product_block {
  margin-bottom: 15px;
  img {
    max-width: 100%;
    text-align: center;
    height: 150px;
  }
}
.product_block_list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.alto_valor_contanier {
  background: hsla(205, 46%, 10%, 1);

  background: linear-gradient(
    90deg,
    hsla(205, 46%, 10%, 1) 0%,
    hsla(191, 28%, 23%, 1) 50%,
    hsla(207, 41%, 27%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(205, 46%, 10%, 1) 0%,
    hsla(191, 28%, 23%, 1) 50%,
    hsla(207, 41%, 27%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(205, 46%, 10%, 1) 0%,
    hsla(191, 28%, 23%, 1) 50%,
    hsla(207, 41%, 27%, 1) 100%
  );

  padding-bottom: 150px;
  .container {
    background: white;
    @extend .material-shadows;
    .product_block_container {
      padding: 50px 20px;
    }
  }
  .heading {
    width: 100%;
    color: white;
    padding: 50px 20px;
    h3,
    p {
      margin: 0;
    }
  }
  .underlined {
    border-color: $mathasa-red;
  }
}
.product_block_alto_valor {
  @extend .product_block_list;
  img {
    height: 200px;
  }
}
.add_product {
  .surface_info {
    color: $text-light;
    width: 100%;
    display: block;
    margin-top: 5px;
  }
}
