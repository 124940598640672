.checkout_page {
  margin-top: 15px;
  .checkout_header {
    img {
      width: 180px;
      margin-bottom: 15px;
      display: block;
    }
  }
  h1 {
    font-weight: lighter;
  }
  .totals {
    padding: 20px;
    p {
      display: flex;
      justify-content: space-between;
    }
  }
  .card {
    h4 {
      margin: 0;
    }
  }
  .no_delivery {
    button {
      line-height: 1.3;
    }
  }
}
.footer_checkout {
  background-color: $text-lighter;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  .legal {
    width: 100%;
    text-align: right;
    padding-right: 100px;
    a {
      margin: 15px;
    }
  }
  a {
    color: $text-default;
    font-size: small;
  }
}

.user_email {
  width: 10 0%;
  margin-bottom: 50px;
}
.branch_secion {
  .btn_rich_container {
    flex-wrap: wrap;
    .btn_rich {
      padding: 20px;
      min-width: 200px;
      border-radius: 5px;
      flex: 1 1;
      margin: 15px;
    }
  }
}
.payment_section {
  .btn_rich_container {
    flex-wrap: wrap;

    .btn_rich {
      @extend .material-shadows;
      position: relative;
      height: auto;
      flex-basis: calc(
        15% - 20px
      ); /* 25px is the combined width of border and padding */
      margin: 10px 15px;
      margin-left: 0px;
      padding: 10px;
      img {
        width: 90px;
        margin: 15px;
      }
      p {
        position: absolute;
        bottom: 3px;
        opacity: 0.8;
      }
      &:first-child {
        margin-right: 15px;
      }
      &.disabled {
        pointer-events: none;
      }
    }
  }
}
$btn_toggles_w: 55px;
.input_btns {
  position: relative;
  padding-right: 15px;
  input {
    padding-right: $btn_toggles_w;
  }
  .btn_toggles {
    display: flex;
    right: 15px;
    top: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: $btn_toggles_w;
    position: absolute;
    .btn {
      min-width: unset;
      padding: 5px;
      color: $text-light;
      &:hover {
        color: $text-blue;
      }
      &.disabled {
        color: $text-lighter;
        &:hover {
          color: $text-lighter;
        }
      }
    }
  }
}

.shipping_cost {
  h5 {
    font-weight: normal;
  }
  h4 {
    font-size: 20px;
    font-weight: normal;
  }
}

.delivery-info {
  border: 2px solid #ef233c;
  background-color: #edf2f4;
  padding: 10px;
  color: #2b2d42;
  border-radius: 10px;
  font-size: 80%;
}
