.sucurales {
  .map_container {
    position: relative;
    .pin_img {
      position: absolute;
      transition: all 0.3s;
      &:hover {
        cursor: pointer;
        transform: scale(1.5);
      }
    }
  }
  .maps_cont {
    position: relative;
    iframe {
      position: absolute;
      z-index: 10;
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 1px 2px 0 rgb(0 0 0 / 24%);
    }
  }
  .branches_cont {
    max-height: 60vh;
    overflow-y: scroll;
    &.active {
      padding-top: 250px;
    }
  }
}
