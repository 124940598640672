.idea-container {
  padding: 0;
  margin-bottom: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  .details {
    padding: 20px;
  }
  .images_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 15px;
    margin: 20px;
  }

  .images_grid img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px; /* Rounded corners for a softer look */
  }

  /* Special classes for larger images to add visual interest */
  .images_grid .large {
    grid-column: span 2;
    grid-row: span 2;
  }

  /* Example for adding hover effect */
  .images_grid img:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
}

.idea-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.idea-image {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
}

.product-list {
  list-style: none;
  padding: 0;
}

.product-list-item {
  margin-bottom: 5px;
  font-size: 14px;
}

.product-link {
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
