.static {
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  p {
    font-size: 1.2em;
  }
  ul {
    list-style: initial;
    padding-left: 5em;
    li {
      font-size: 1.2em;
    }
  }
  .content {
    .blocks-gallery-grid {
      li {
        display: inline-block;
        margin-right: 15px;
      }
    }
    figure {
      img {
        max-width: 100%;
        height: auto;
      }
      &.aligncenter {
        display: flex;
        justify-content: center;
      }
    }
  }
}
