.product_reviews {
  .rate_cloumn {
    & > * {
      margin: 0;
      margin-top: 30px;
    }
  }
  .rate_producs {
    .btn {
      margin: 0;
      min-width: unset;
      padding: 2px;
      font-size: 20px;
      color: $color-gold;
    }
  }

  .empty_jumbo {
    i {
      display: inline-block;
    }
  }
}
