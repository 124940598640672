.top_nav {
  background-color: $main-color;
  .container {
    display: flex;
    justify-content: space-between;
  }
  a,
  span,
  button {
    color: $text-default;
    font-size: 12px;
    margin-right: 10px;
    text-decoration: none;
    padding: 0;
  }
  .btn {
    text-transform: none;
  }
}
.main_navbar {
  background-color: white;
  display: block;
  -webkit-transition: all 0.4s; /* Safari */
  transition: all 0.4s;
  top: 24px;
  a,
  button &:not(.btn-light) {
    color: white;
    &:hover {
      color: darken(white, 20);
      background-color: rgba(255, 255, 255, 0);
    }
    &:focus {
      outline: 0;
    }
    &.disabled,
    .navbar-brand {
      height: auto;
    }
  }
  .navbar-brand {
    img {
      max-width: 200px;
    }
  }
  &.top {
    top: 0;
  }
  &.invert {
    a,
    button {
      &:not(.btn-light) {
        color: $main-color;
        &:hover {
          color: darken($main-color, 20);
          background-color: rgba(255, 255, 255, 0);
        }
        &:focus {
          outline: 0;
        }
      }
    }
  }
  .navbar_items {
    a,
    button {
      &.navbar-brand {
        color: $text-default;
        font-size: clamp(10px, 1.1vw, 16px);
        &.alto_valor {
          color: white;
          border-radius: 10px 10px 0 0;
          background: $mathasa-red;
          padding: 5px 10px;
          @extend .material-shadows;
          i {
            color: $color-gold;
          }
        }
        &.eco_friendly {
          color: white;
          border-radius: 10px 10px 0 0;
          background: #57aa24;
          padding: 5px 10px;
          @extend .material-shadows;
          i {
            color: $color-gold;
          }
        }
      }
    }
  }
}
.decasajuarez,
.decasa,
.decasahermosillo {
  .navbar-brand {
    img {
      max-width: 90px;
      margin-right: 30px;
      margin-left: 30px;
    }
  }
}
.idecasa {
  .navbar-brand {
    img {
      max-width: 120px;
      margin-right: 30px;
      margin-left: 30px;
    }
  }
}
.nav-link.disabled {
  color: $main-color-disabled !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
.search_bar_main {
  background: #fff;
  width: 80%;
  max-width: 680px;
  margin: 0 auto;
  height: 42px;
  margin-top: 11px;
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  i.search_bar_search_icon {
    height: 100%;
    line-height: 42px;
    float: left;
    width: 52px;
    text-align: center;
    color: rgba(68, 68, 68, 0.5);
  }

  input#search_bar_input {
    height: 42px;
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
    background: transparent;
    width: calc(100% - 52px);
    outline: none;
    padding-right: 32px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75);
    font-weight: lighter;
  }

  i.clear_search_bar_field {
    position: absolute;
    right: 12px;
    top: 14px;
    color: rgba(189, 189, 189, 0.84);
    display: none;
    font-size: 15px;
    &:hover {
      cursor: pointer;
    }
  }
}

.footer .app {
  background-color: $main-color;
  height: 70px;
  position: fixed;
  bottom: 0;
  z-index: $zindex-fixed;
  width: 100%;

  a,
  button {
    color: lighten($main-color-dark, 30%);
    &.active {
      color: $main-color-dark;
    }
  }
}

.footerCta {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  z-index: $zindex-fixed;
  &.unactive {
    bottom: -500px;
  }
}

.products_menu {
  position: absolute;
  max-height: 80vh;
  overflow-y: scroll;
  z-index: $zindex-popover;
  background-color: #fff;
  top: 100%;
  border-radius: 0 0 20px 20px;
  width: 100%;
  border: 1px solid $text-lightest;
  border-top: 3px solid $secondary-color;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  & > ul {
    flex: 1 1;
    border-right: 1px solid $text-lighter;
    margin: 0;
    padding: 0;
    li {
      list-style: none;

      padding: 0;
      margin: 0;
      padding: 5px 15px;
      &:hover {
        background-color: $text-lightest;
        cursor: pointer;
        & > a {
          font-size: bold;
          color: $text-default;
          text-decoration: none;
        }
      }
      &.active {
        background-color: $text-lightest;
        cursor: pointer;
        & > a,
        & > button {
          font-size: bold;
          color: $text-default;
          text-decoration: none;
          i {
            color: $text-default;
          }
        }
      }
      a {
        color: $text-default;
        display: block;
        font-size: 18px;
        font-weight: 600;
        i {
          font-size: 8px;
          color: white;
        }
      }
    }
  }

  .menu_items {
    flex: 3 1;
    text-align: left;
    padding: 15px;
    .childs {
      margin-bottom: 15px;
      margin-bottom: 15px;
      display: inline-block;
      width: 25%;
      vertical-align: top;
      & > a {
        text-align: left;
        color: $text-default;
        font-weight: bold;
        font-size: 15px;
        padding: 0 15px;
      }
      ul {
        margin: 0;
        padding: 0 15px;
        a {
          text-align: left;
          color: $text-default;
          display: block;
          padding: 2px 0;
          margin: 0;
          font-size: 15px;
        }
      }
    }
  }
}
.conoce_menu {
  @extend .products_menu;
  width: 250px;
  left: 375px;
}
.mob_nav {
  position: fixed;
  transition: left 0.2s ease-in-out;
  left: -80%;
  top: 0;
  bottom: 0;
  background-color: $colot-gray;
  width: 80%;
  overflow: hidden;
  &.active {
    left: 0;
  }
  .header {
    height: 15%;
    background-color: $text-lightest;
    display: flex;
    align-items: center;
    padding: 0 20px;
    a {
      color: $main-color-dark;
    }
    i {
      font-size: 40px;
      margin-right: 15px;
    }
    span {
      * {
        display: block;
      }
    }
  }
  .body {
    padding-top: 15px;
    height: 85%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    .mob_nav_items {
      a,
      button {
        display: block;
        padding: 15px;
        color: $text-default;
        font-size: 17px;
      }
      ul {
        padding: 0;
        li {
          list-style: none;
        }
      }
    }
    .mob_level {
      position: absolute;
      left: 100%;
      transition: left 0.5s ease-in-out;
      top: 0;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      background-color: $colot-gray;
      padding-top: 15px;
      &.active {
        left: 0;
      }
      .back_btn {
        font-size: 13px;
        width: 100%;
        text-align: left;
        text-transform: capitalize;
      }
      .childs {
        margin-bottom: 15px;
        & > a {
          text-align: left;
          color: $text-default;
          font-weight: bold;
          font-size: 13px;
          padding: 5px 15px;
        }
        ul {
          margin: 0;
          padding: 0 15px;
          a {
            text-align: left;
            color: $text-light;
            display: block;
            padding: 10px 0;
            margin: 0;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.search_results_container {
  position: relative;
  .search_results {
    position: absolute;
    background-color: #fff;
    border: 0 0 10px 10px;
    border: 1px solid $text-lighter;
    min-height: 50px;
    max-height: 70vh;
    overflow-y: scroll;
    width: 95%;
    padding: 0;
    z-index: $zindex-modal-backdrop;
    .search_item {
      display: flex;
      padding: 10px 20px;
      margin: 0;
      position: relative;
      &:hover {
        background-color: $text-lightest_2;
        cursor: pointer;
      }
      a {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      img {
        width: 70px;
        border-radius: 10px;
        margin-right: 20px;
      }
      .desc {
        & > * {
          margin: 0;
        }
        h4 {
          font-size: 15px;
        }
        p {
          font-size: 13px;
        }
      }
    }
    .no_results {
      width: 100%;
      padding-top: 20px;
      font-size: 15px;
      color: #333;
      text-align: center;
    }
  }
  .loading {
    text-align: center;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      div {
        background-color: $secondary-color;
        opacity: 0.8;
      }
    }
  }
}

@media (max-width: 575px) {
  .main_navbar {
    top: 0;
    z-index: $zindex-fixed;
  }
  .nav_container {
    img {
      width: 150px;
    }
  }
  .mob_search_bar {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .search_results_container {
    .search_results {
      .search_item {
        width: auto;
        height: 80px;
      }
    }
  }
}
