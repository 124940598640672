.filters {
  width: 150px;
  margin-right: 175px;
  .filter {
    &_container {
      max-height: 145px;
      overflow: hidden;
      transition: all 0.5s ease-in-out;
      &.expanded {
        max-height: 1000px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    width: auto;
    margin-right: 0;
  }
}
