.text_main {
  color: $main-color;
}

.text_default {
  color: $text-default;
}

.text_success {
  color: $color-success;
}

.word_break {
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for WebKit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

$i: 5;
@while $i > 0 {
  .display-#{$i} {
    @extend .word_break;
  }
  $i: $i - 1;
}

.underlined {
  border-bottom: 3px solid $main-color;
  max-width: 60%;
}

.opacity_0 {
  opacity: 0;
}

.opacity_1 {
  opacity: .1;
}

.opacity_2 {
  opacity: .2;
}

.opacity_3 {
  opacity: .3;
}

.opacity_4 {
  opacity: .4;
}

.opacity_5 {
  opacity: .5;
}

.opacity_6 {
  opacity: .6;
}

.opacity_7 {
  opacity: .7;
}
