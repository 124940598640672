/// layout colors
$main-color: #f8cf00;
$mathasa-red: #ed3237;
$secondary-color: #f39634;
$main-color-darker: darken($main-color, 20);
$main-color-lighter: lighter($main-color, 20);
$main-color-complementary: #d72638;
$main-color-dark: #39393b;
$main-color-light: #eaf6ff;
$main-color-disabled: lighter($main-color, 20);
$cta-cart: #f8cf00;
$cta-fav: #ff6028;
$color-success: #01e668;
$colot-gray: #e6e6e7;
$color-gold: #ffd700;
$text-blue: #3e78b2;
$text-default: #262627;
$text-light: #9b9b9b;
$text-lighter: #e6e6e6;
$text-lightest: #eeeeee;
$text-lightest_2: #f5f5f5;
$color-whatsapp: #25d366;

// design mathasa layout colors
$design-main-color: #d2e9e3;
$design-color-success: #23747d;
$design-color-danger: #df7a5f;
$design-text-blue: #a8d6e5;
$design-color-background: #e2e2e2;
$design-cta-cart: $design-color-success;

/// screen sizes
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xl-min: 1400px;

//footer height
$footer-height: 440px;
$footer-height-xs: 875px;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

//// social media colors
$facebook: #4267b2;
$twitter: #1da1f2;
$instagram: #c32aa3;
$youtube: #ff0002;
$linkedin: #007bb5;
$twitter: #1da1f2;
