.instagram_feed {
  clear: both;
  height: 430px;
  .instagram_item {
    width: 200px;
    height: 200px;
    margin: 1px;
    float: left;
    position: relative;
    &:first-child {
      width: 400px;
      height: 400px;
    }
    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      background-color: rgba($color: #000000, $alpha: 0.7);
      color: #e4e4e4;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-decoration: none;
      display: flex;
      &:hover {
        opacity: 1;
      }
    }
  }
}
