.backdrop {
  position: fixed;
  z-index: $zindex-modal-backdrop;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.2s linear;
  pointer-events: none;
  &.active {
    pointer-events: initial;
    opacity: 1;
  }
}
.backdrop_nav {
  position: fixed;
  z-index: $zindex-sticky;
  background-color: transparent;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: none;
  &.active {
    display: block;
  }
}
.custom_modal {
  position: fixed;
  z-index: $zindex-modal;
  border-radius: 20px;
  background: white;
  top: 5vh;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  transition: all 0.25s linear;
  transform: translateY(-100vh);
  opacity: 0;
  min-height: 200px;
  width: 50%;
  overflow: hidden;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  pointer-events: none;
  &.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: initial;
  }
  .header {
    background-color: $main-color;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    h3 {
      font-weight: lighter;
      margin: 0;
      color: #333;
      font-size: 18px;
    }
    button {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
  .body {
    padding: 25px;
    h5 {
      color: $text-default;
    }
    select {
      max-width: 50%;
      margin: 0 auto;
    }
    p {
      color: $text-default;
    }
  }
  .modal_footer {
    p {
      color: $text-default;
    }
  }
}
.product_modal {
  min-height: 90vh;
  width: 90%;
  overflow: hidden;
  .body {
    height: 100vh;
    overflow: hidden;
    display: grid;
    grid-template-rows: 2fr 1fr;
    grid-template-areas:
      "reco reco cart "
      "reco reco cart "
      "reco reco cta ";
    h4 {
      margin: 0;
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 15px;
    }
    & > div {
      padding: 20px;
    }
    .related_products {
      grid-area: reco;
    }
    .products {
      grid-area: cart;
    }
    .foot {
      border-top: 1px solid $text-lighter;
      padding-top: 15px;
      grid-area: cta;
    }
    .goto_cart {
      .total {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
      }
    }
    .scroll_container {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}

.full_loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 2555, 255, 0.7);
  z-index: $zindex-fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 4em;
  }
  p {
    color: $text-default;
  }
  .loader {
    width: 100%;
    margin: 0 auto;
    border: 1px solid $text-light;
    height: 5px;
    overflow: hidden;
    position: relative;
    > div {
      width: 10%;
      position: absolute;
      height: 5px;
      background-color: $text-blue;
    }
  }
}

.save_order_modal {
  .body {
    padding: 20px 10%;
    text-align: center;
  }
}

@media (max-width: 575px) {
  .custom_modal {
    width: 90%;
    .body {
      select {
        max-width: 100%;
      }
    }
  }
  .product_modal {
    max-height: 90vh;
    .body {
      display: block;
      height: 90vh;
      overflow: scroll;
      padding-bottom: 180px;
      .border-right {
        border: none !important;
      }
      & > div {
        padding: 0px;
      }
      .foot {
        position: fixed;
        bottom: 0;
        background-color: #fff;
        left: 0;
        right: 0;
        padding: 20px;
      }
    }
  }

  .backdrop_nav {
    background-color: rgba($color: #000000, $alpha: 0.3);
    &.search {
      z-index: $zindex-dropdown;
    }
  }
}
