.hero_container {
  display: flex;
  align-items: center;
  .full_link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .finder_select {
    margin: 0 auto;
    background-color: rgba($color: $text-default, $alpha: 0.7);
    min-height: 40vh;
    max-width: 90%;
    padding: 50px 30px;
    color: white;
    h1 {
      color: white;
      text-align: center;
    }
    select {
      background-color: transparent;
      color: white;
      border: 1px solid white;
      margin-bottom: 10px;
      -webkit-appearance: none;
      border-radius: 0;
      background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='white' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
      background-position: 100% 50%;
      background-repeat: no-repeat;
      &:active {
        color: red;
      }
    }
    .btn {
      background-color: #f7f5f1;
    }
  }
}

.featured {
  margin: 15px 0;
  min-height: 90vh;
  .background_img {
    background-size: cover;
    position: relative;
    background-position: center center;
    .bg_type {
      display: flex;
      pointer-events: none;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: white;
      font-size: 1.5em;
      font-weight: 700;
      background-color: rgba($color: #000000, $alpha: 0.5);
      transition: all 0.3s ease-in-out;
      text-transform: uppercase;
      position: relative;
      .flag {
        $ribbon-color: #ec3137;
        background-color: $ribbon-color;
        max-width: 80%;
        min-height: 50px;
        display: flex;
        align-items: center;
        text-align: left;
        position: absolute;
        left: -8px;
        top: 20px;
        padding: 0 15px;

        &:before,
        &:after {
          content: '';
          position: absolute;
        }
        &:before {
          height: 0;
          width: 0;
          top: -8.5px;
          left: 0.1px;
          border-bottom: 9px solid black;
          border-left: 9px solid transparent;
        }
        &:after {
          height: 0;
          width: 0;
          right: -14.5px;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 15px solid $ribbon-color;
        }
      }
    }
    &:hover {
      cursor: pointer;
      & > .bg_type {
        background-color: rgba($color: #000000, $alpha: 0.2);
      }
    }
  }
}

.promotions {
  margin: 1rem;
  display: grid;
  justify-content: space-between;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 1060px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 720px) {
    grid-template-columns: repeat(2, 1fr);
  }
  img {
    width: 250px;
    height: 250px;
    @media screen and (max-width: 1060px) {
      width: 120px;
      height: 120px;
    }
    background-size: cover;
    position: relative;
    background-position: center center;
    margin-bottom: 5px;
  }
  .regular-price {
    text-decoration: line-through;
  }
}

.best-sellers {
  width: 100%;
  @media screen and (min-width: 1300px) {
    width: 1300px;
  }
}

.contact_container {
  background-image: url(https://images.unsplash.com/photo-1547149600-a6cdf8fce60c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80);
  background-size: cover;
  color: white;
  .container {
    padding: 40px 0;
    p {
      text-transform: capitalize;
      &.active {
        border: 2px solid $main-color;
        padding: 20px;
        background: rgba($color: #000, $alpha: 0.8);
      }
    }
  }
}
/// affect single segment of screen sizes
@media (max-width: 575px) {
  .featured {
    display: grid;
    grid-template-rows: auto;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'main main'
      'two tree'
      'for bott'
      'one one';
    .background_img {
      margin: 5px;
      text-align: center;
      &:nth-child(1) {
        grid-area: main;
      }
      &:nth-child(2) {
        grid-area: one;
      }
      &:nth-child(3) {
        grid-area: two;
      }
      &:nth-child(4) {
        grid-area: tree;
      }
      &:nth-child(5) {
        grid-area: for;
      }
      &:nth-child(6) {
        grid-area: bott;
      }

      .bg_type {
        justify-content: flex-start;
        align-items: flex-start;
        .flag {
          position: relative;
          font-size: 14px;
        }
      }
    }
  }
}

.carouselArrow {
  background: rgba(0, 0, 0, 0);
  transition: all 0.25s ease-in;
  &:hover {
    background-color: rgba(
      0,
      0,
      0,
      0.2
    ); // Use decimal (0 to 1) for alpha value
  }
}

.carouselIndicator {
  border-radius: 100%;
}
