.card_icon {
  background-color: $text-lightest_2;
  &.selected {
    border: 2px solid $text-blue;
  }
  p {
    margin: 0;
  }
  small {
    font-size: 14px;
    font-weight: 100;
  }
  &.sm {
    .icon_rounded {
      height: 40px;
      width: 40px;
      i {
        font-size: 15px;
      }
    }
  }
}
