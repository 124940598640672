.main_background {
  padding: 180px 0 0px;
  overflow: hidden;
  min-height: 99vh;
  margin-top: -80px;
}
.full_bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.parallax {
  background-attachment: fixed;
  background-color: $main-color;
  background-blend-mode: soft-light;
}

.bg_transition {
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
}

.features-left {
  position: relative;
  padding-right: 70px;
  text-align: right;
  margin-bottom: 50px;
  p {
    color: $text-light;
  }
  i {
    position: absolute;
    top: calc(30% - 15px);
    right: 0;
  }
}
.features-right {
  position: relative;
  padding-left: 70px;
  margin-bottom: 50px;
  p {
    color: $text-light;
  }
  i {
    position: absolute;
    top: calc(30% - 15px);
    left: 0;
  }
}

.get_started {
  background-color: $main-color;
  padding: 30px 0;
}

.divider {
  width: 100%;
  height: 10px;
  margin: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}

.carousel_container {
  position: relative;
  a,
  button {
    position: absolute;
    top: calc(50% - 24px); ///divided by button height
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
    &.left {
      left: 15px;
    }
    &.right {
      right: 15px;
    }
  }
}
.carousel_body {
  position: relative;
  overflow: hidden;
  min-height: 80vh;
  .carousel_item {
    -webkit-transition: left 0.2s ease-in-out;
    transition: left 0.2s ease-in-out;
    position: absolute;
    left: 100%;
    min-height: 80vh;
    width: 100%;
    &.active {
      left: 20px;
    }
    &.not_active {
      left: -100%;
    }
  }
}

.carousel .slide {
  background: transparent !important;
}
