.cart_page {
  h1 {
    text-align: center;
    font-weight: normal;
    font-size: 1.5em;
  }
  h3 {
    font-weight: lighter;
    font-size: 1.5em;
  }
  .cart_items {
    .headers {
      margin-top: 30px;
      padding-bottom: 20px;
      border-bottom: 1px solid $text-lighter;
      h3 {
        color: $text-light;
        font-weight: normal;
        font-size: 18px;
      }
    }
  }
  .promotions {
    display: flex;
    align-items: center;
    p {
      margin: 0;
    }
    b {
      text-transform: uppercase;
    }
  }
}
.checkout_btn {
  font-size: 20px;
  position: relative;
  padding: 25px;
  color: white !important;
  text-transform: none;
  color: white !important;
  background-color: #f7a807;
}
.payment_icons {
  align-items: center;
  margin-top: -10px;
  margin-bottom: 20px;
  padding: 15px 10px;
  padding-top: 25px;
  border-radius: 0 0 20px 20px;
  background-color: #f6f5f8;
  .icons {
    color: $main-color-dark;
    font-size: 30px;
    display: flex;
    justify-content: space-around;

    img {
      height: 14px;
    }
    @media (min-width: 1000px) {
      img {
        height: 20px;
      }
    }
  }
}

.products_needed {
  p {
    font-weight: lighter;
    margin-bottom: 20px;
  }
}
.cart_totals {
  padding: 0 5%;
  .totals {
    width: 100%;
    margin-bottom: 30px;
    & > div:first-child {
      display: flex;
      justify-content: space-between;
    }
    .promotions_totals {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    h5 {
      font-weight: lighter;
      &.total {
        font-weight: bold;
      }
    }
    h6 {
      font-size: 17px;
      margin: 0;
      color: tomato;
    }
  }
  .ctas {
    width: 100%;
  }
}

.ticket {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin-top: 30px;
  padding: 20px;
  border-radius: 10px;
  background: #fbfbfb;
  box-shadow: 2px 2px 15px 0px $text-light;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 5px;
    height: 6px;
    width: 290px;
  }

  &:before {
    top: -5px;
    background: radial-gradient(
        circle,
        transparent,
        transparent 50%,
        #fbfbfb 50%,
        #fbfbfb 100%
      ) -7px -8px / 16px 16px repeat-x;
  }

  &:after {
    bottom: -5px;
    background: radial-gradient(
        circle,
        transparent,
        transparent 50%,
        #fbfbfb 50%,
        #fbfbfb 100%
      ) -7px -2px / 16px 16px repeat-x;
  }
  .header {
    display: flex;
    justify-content: space-between;
    h4 {
      font-size: 1em;
    }
    p {
      font-size: 0.7em;
    }
  }
  &__content {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    border: 6px solid #d8d8d8;
  }

  &__text {
    width: 400px;
    font-family: "Helvetica", "Arial", sans-serif;
    font-size: 3rem;
    font-weight: 900;
    text-transform: uppercase;
    color: #c6dede;
    transform: translate(-25px, 25px) rotate(-55deg);
  }

  &_ul {
    li {
      display: flex;
      align-items: center !important;
      margin: 15px 0;
      .media-body {
        font-size: 15px;
        span {
          color: $text-light;
        }
      }
      i {
        font-size: xx-large;
      }
    }
  }
}

.cart_row {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $text-lightest;
  &:last-child {
    border-bottom: none;
  }
}
