.btn-cart {
  background-color: $cta-cart;
  color: $main-color-dark !important;
  border-radius: 10px;
  &:hover {
    background-color: darken($color: $cta-cart, $amount: 5) !important;
  }
  @media screen and (max-width: 768px) {
    &.fixed {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $zindex-fixed;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.btn-payment {
  display: block;
  width: 100%;
  @media screen and (max-width: 768px) {
    &.fixed {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $zindex-fixed;
    }
  }
}

.btn_whatsaap {
  background-color: $color-whatsapp;
  color: white;
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 23px;
  align-items: center;
  &:hover {
    color: white;
    background-color: darken($color: $color-whatsapp, $amount: 5) !important;
    text-decoration: none;
  }
  &.fixed {
    position: fixed;
    bottom: 100px;
    right: 15px;
    z-index: $zindex-sticky;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

.btn_share {
  background-color: transparent;
  color: #383a40;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  min-height: 35px;
  border-color: transparent;
  &:hover {
    color: black;
    text-decoration: none;
  }
}

.btn-outline-cart {
  border: 1px solid $cta-cart;
  color: $main-color-dark !important;
  border-radius: 10px;
  &:hover {
    background-color: $cta-cart;
  }
}

.btn_lg {
  min-width: 122px;
  padding: 10px 16px;
  line-height: 1.3;
}

.btn-cont {
  &-50 {
    .btn {
      width: 45%;
      margin: 5px;
    }
  }
}

.btn-light {
  background-color: $text-lightest;
  color: $text-default;
  &.search_bar {
    border: 1px solid $text-lighter;
  }
}

////social buttons
$socials: (facebook, $facebook), (facebook-f, $facebook),
  (instagram, $instagram), (youtube, $youtube), (twitter, $twitter),
  (linkedin, $linkedin);
.social {
  @each $id, $color in $socials {
    .#{$id} {
      transition: color 0.3s ease-in-out;
      &.colored {
        color: $color;
      }
      &.hover:hover {
        color: $color;
      }
      &.hover_bg:hover {
        background-color: $color;
        color: #ffffff;
      }
    }
  }
}

.color_filter {
  width: 30px;
  min-width: auto;
  height: 30px;
  border-radius: 100%;
  background-color: $text-lightest;
  border: 2px solid $text-lightest;
  margin-right: 5px;
  &.selected {
    border: 2px solid $text-default;
  }
}
.btn_rich_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  &.horizontal {
    flex-direction: column;
    .btn_rich {
      margin-bottom: 10px;
      width: 100%;
      min-height: 100px;
      flex-direction: row;
      justify-content: flex-start;
      &:last-child {
        margin-left: 0;
      }
      p {
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
      .cards_icons {
        margin-right: 15px;
        top: 0;
        right: 0;
        left: unset;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        i {
          margin: 0;
          margin-right: 0;
        }
      }
      i {
        margin: 0;
      }
      &.rappi_btn {
        img {
          width: 250px;
        }
      }
      &.coppel_btn {
        background-color: #fbdd31;
        color: #2266ae;
        flex-wrap: wrap;
      }
      &.disabled {
        filter: grayscale(1);
      }
    }
  }

  > * {
    flex: 1 1;
    &:first-child {
      margin-right: 15px;
    }
  }
  .btn_rich:last-child {
    margin-left: 15px;
  }
}
.btn_rich {
  border: 1px solid $text-default;
  position: relative;
  text-align: center;
  border-radius: 5px;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 160px;
  &.small {
    min-height: initial;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
  }
  &:hover {
    cursor: pointer;
  }
  &.active {
    border: 5px solid $text-blue;
  }
  p {
    margin: 0;
    &.text-help {
      color: $text-light;
    }
  }
  i {
    font-size: xx-large;
    margin-bottom: 20px;
  }
  .cards_icons {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 80%;
    left: 0;
    i {
      color: $text-light;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.no_focus {
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.full_link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.hover {
  &:hover {
    cursor: pointer;
  }
}

.badge_main {
  background-color: $main-color;
}

.rappi_pay_btn {
  background-color: #eb4025;
  border-radius: 15px;
  img {
    width: 60%;
  }
}

.brad-5px {
  border-radius: 5px !important;
}

.coppel_btn {
  &.active {
    background-color: #fbdd31;
  }
  img {
    width: 150px;
  }
}

.atrato_btn {
  &.active {
    background-color: #162d4f;
    color: white;
  }
}
.paypal_btn {
  &.active {
    background-color: #fac43a;
    color: #3396d4;
  }
}

.online_btn {
  margin-right: 15px !important;
  &.active {
    background-color: #33a744;
    color: #f0f0f0;
  }
}
.transfer_btn {
  &.active {
    background-color: #f5f5f5;
    color: #333333;
  }
}
