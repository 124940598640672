/// affect single segment of screen sizes
@media (max-width: 575px) {
  body {
    margin-bottom: $footer-height-xs;
  }
  .footer {
    height: $footer-height-xs;
  }
  .main_background {
    padding-top: 90px;
  }
  .xs_hidden {
    display: none;
  }
  .xs_block {
    display: block;
    width: 100%;
  }
  .xs_hidden_opacity {
    opacity: 0 !important;
  }
  .w_100_xs {
    width: 100%;
  }
  .features-section {
    .nav {
      margin-bottom: 20px;
      li {
        width: 100%;
        button.nav-link {
          text-align: center;
          width: 100%;
          padding: 20px;
          display: block;
          border: 2px solid white;
          &.active {
            border: 2px solid $main-color;
          }
        }
      }
    }
  }
  .btn_rich_container.mob_full {
    flex-direction: column;
    > * {
      flex: 1 1;
      &:first-child {
        margin-right: 0px;
      }
      &:last-child {
        margin-left: 0;
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .product_block_list {
    width: 255px;
  }
} // end of $screen-sm-min

@media (min-width: $screen-md-min) {
  .hero_container {
    .finder_select {
      margin: 0 0;
      max-width: 30%;
    }
  }
  .featured {
    display: grid;
    grid-template-rows: auto;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "main main  side side"
      "main main one two"
      "sidel sidel sider sider";
    .background_img {
      &:nth-child(1) {
        grid-area: main;
      }
      &:nth-child(2) {
        grid-area: side;
      }
      &:nth-child(3) {
        grid-area: one;
      }
      &:nth-child(4) {
        grid-area: two;
      }
      &:nth-child(5) {
        grid-area: sidel;
      }
      &:nth-child(6) {
        grid-area: sider;
      }
    }
  }
  .product_block {
    max-width: 33%;
  }
  .product_modal {
    width: 80%;
    .body {
      height: 80vh;
      grid-template-rows: 2fr 1fr;
      grid-template-areas:
        "reco reco cart "
        "reco reco cart "
        "reco reco cta ";
      .related_products {
        grid-area: reco;
      }
      .products {
        grid-area: cart;
      }
      .foot {
        grid-area: cta;
      }
    }
  }
  .user_email {
    width: 70%;
  }
  .cart_row {
    .products {
      flex: 5;
    }
    .price {
      flex: 2;
    }
    .quanty {
      flex: 2;
    }
    .del {
      flex: 1;
    }
  }
  
} // end of $screen-md-min

@media (max-width: $screen-sm-min) {
  .product_block_list {
    width: 50%;
  }
}