.text-primary {
  color: $main-color !important;
}
.btn-primary {
  color: white !important;
  background-color: $main-color !important;
  outline-color: $main-color;
  &:hover {
    background-color: darken($main-color, 1) !important;
    color: white !important;
  }
}
.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  color: $text-light;
  font-size: 0.7em;
  a {
    color: $text-light;
  }
  .breadcrumb-item {
    & + .breadcrumb-item::before {
      color: $text-lighter;
      content: ">";
    }
    &:hover {
      cursor: pointer;
    }
    &.active {
      color: $text-blue;
      font-weight: bold;
    }
  }
}
ul {
  list-style: none;
  padding-left: 0;
}

.alert {
  p {
    margin: 0;
    i {
      margin-right: 10px;
    }
  }
}
